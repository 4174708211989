import React, { useState, useContext } from "react"
import styled from "styled-components"
import { Container } from "react-bootstrap"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import { LocalizedLink } from "gatsby-theme-i18n"
import { Trans } from "@lingui/macro"

import { MdMenu } from "@react-icons/all-files/md/MdMenu"

import GlobalContext from "../../context/GlobalContext"
import Offcanvas from "../Offcanvas"
import { Button } from "../Core"
import NestedMenu from "../NestedMenu"
import { device } from "../../utils"
import Logo from "../Logo"
import { getMenuItems } from "./menuItems"

const SiteHeader = styled.header`
  padding: 10px 0 10px 0;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 999;
  @media ${device.xl} {
    position: fixed;
    transition: 0.4s;
    &.scrolling {
      transform: translateY(-100%);
      transition: 0.4s;
    }
    &.reveal-header {
      transform: translateY(0%);
      box-shadow: 0 12px 34px -11px rgba(65, 62, 101, 0.1);
      z-index: 9999;
      background: ${({ dark, theme }) =>
    dark ? theme.colors.dark : theme.colors.light};
    }
  }
`

const ToggleButton = styled.button`
  &&& {
    color: ${({ dark, theme }) =>
    dark ? theme.colors.lightShade : theme.colors.darkShade};
    border-color: ${({ dark, theme }) =>
    dark ? theme.colors.lightShade : theme.colors.darkShade};
  }
`

const Menu = styled.ul`
  @media ${device.xl} {
    display: flex;
    justify-content: flex-end;
  }
  .dropdown-toggle {
    cursor: pointer;
  }
  > li.nav-item {
    &:hover {
      color: ${({ dark, theme }) => (dark ? theme.colors.light : theme.colors.darkShade)};
      text-decoration: underline 1px !important;
      text-decoration-color: color: ${({ dark, theme }) => (dark ? theme.colors.light : theme.colors.primary)};
      text-underline-offset: 6px;
    }
    > a.nav-link {
      &.active {
        color: ${({ dark, theme }) => (dark ? theme.colors.light : theme.colors.primary)};
        text-decoration: underline 3px !important;
        text-decoration-color: color: ${({ dark, theme }) => (dark ? theme.colors.light : theme.colors.primary)};
        text-underline-offset: 6px;
      }
      @media ${device.xl} {
        color: ${({ dark, theme }) =>
    dark ? theme.colors.light : theme.colors.darkShade};
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        padding-top: 18px;
        padding-bottom: 18px;
        padding-left: 18px;
        padding-right: 18px;
      }
      &:hover {
        color: ${({ dark, theme }) => (dark ? theme.colors.light : theme.colors.darkShade)};
      }
    }
  }
  .nav-item.dropdown {
    @media ${device.xl} {
      position: relative;
      z-index: 99;
    }
    &:hover {
      > .menu-dropdown {
        @media ${device.xl} {
          top: 90%;
          opacity: 1;
          pointer-events: visible;
        }
      }
    }
  }
`

const MenuDropdown = styled.ul`
  list-style: none;
  @media ${device.xl} {
    top: 110%;
    position: absolute;
    min-width: 227px;
    max-width: 227px;
    box-shadow: 0 52px 54px rgba(65, 62, 101, 0.3);
    border-radius: 8px;
    border: 1px solid #e5e5e5;
    background-color: #ffffff;
    padding: 15px 0px;
    z-index: 99;
    opacity: 0;
    transition: opacity 0.4s, top 0.4s;
    pointer-events: none;
    left: -90%;
    border-radius: 0 0 10px 10px;
    border: 1px solid #eae9f2;
    background-color: #ffffff;
    display: block;
    border-top: ${({ theme }) => `3px solid ${theme.colors.secondary}`};
  }
  > .drop-menu-item {
    color: ${({ theme }) => theme.colors.dark};
    font-size: 16px;
    font-weight: 300;
    letter-spacing: -0.5px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;

    a {
      color: ${({ theme }) => theme.colors.dark};
      transition: all 0.3s ease-out;
      position: relative;
      display: flex;
      align-items: center;
      &.dropdown-toggle::after {
        display: inline-block;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.325em solid;
        border-right: 0.325em solid transparent;
        border-bottom: 0;
        border-left: 0.325em solid transparent;
        position: relative;
        top: 1px;
        margin-left: auto;
        transform: rotate(-90deg);
        transition: 0.4s;
      }
    }

    &:hover {
      > a {
        color: ${({ theme }) => theme.colors.secondary};
        text-decoration: none;
        &::after {
          transform: rotate(0deg);
        }
      }
    }
    &.dropdown {
      position: relative;

      &:hover {
        > .menu-dropdown {
          @media ${device.xl} {
            top: 10px;
            opacity: 1;
            pointer-events: visible;
            transform: translateX(-100%);
          }
        }
      }
      > .menu-dropdown {
        border-top-color: ${({ theme }) => theme.colors.primary};
        @media ${device.xl} {
          top: 10px;
          left: 0%;
          opacity: 0;
          transform: translateX(-110%);
          transition: 0.4s;
          pointer-events: none;
        }
        > .drop-menu-item {
          @media ${device.xl} {
            padding-left: 30px;
            padding-right: 30px;
          }
        }
      }
    }
  }
  &.dropdown-right {
    left: auto;
    right: -90%;
  }
`

const Header = ({ isDark = false, pageContext }) => {
  const menuItems = getMenuItems()
  const gContext = useContext(GlobalContext)
  //const [showScrolling, setShowScrolling] = useState(false);
  const [showReveal, setShowReveal] = useState(false)

  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y < 0) {
      //setShowScrolling(true);
    } else {
      //setShowScrolling(false);
    }
    if (currPos.y < -1) {
      setShowReveal(true)
    } else {
      setShowReveal(false)
    }
  })

  return (
    <>
      <SiteHeader
        className={`sticky-header  ${showReveal ? "reveal-header" : ""}`}
        dark={isDark && !showReveal ? 1 : 0}
      >
        <Container fluid>
          <nav className="navbar site-navbar offcanvas-active navbar-expand-xl navbar-light px-1 px-md-3">
            {/* <!-- Brand Logo--> */}
            <div className="brand-logo">
              <Logo white={isDark && !showReveal} />
            </div>
            <div className="collapse navbar-collapse">
              <div className="navbar-nav ml-lg-auto mr-3">
                <Menu
                  className="navbar-nav d-none d-xl-flex"
                  dark={isDark && !showReveal ? 1 : 0}
                >
                  {menuItems.map(
                    (
                      { label, isExternal = false, name, items, ...rest },
                      index,
                    ) => {
                      const hasSubItems = Array.isArray(items)
                      return (
                        <React.Fragment key={name + index}>
                          {hasSubItems ? (
                            <li className="nav-item dropdown" {...rest}>
                              <a
                                className="nav-link dropdown-toggle"
                                role="button"
                                data-toggle="dropdown"
                                aria-expanded="false"
                                href="/#"
                                onClick={(e) => e.preventDefault()}
                              >
                                {label}
                              </a>
                              <MenuDropdown
                                className="menu-dropdown dropdown-right"
                                dark={isDark ? 1 : 0}
                              >
                                {items.map((subItem, indexSub) => {
                                  const hasInnerSubItems = Array.isArray(
                                    subItem.items,
                                  )
                                  return (
                                    <React.Fragment
                                      key={subItem.name + indexSub}
                                    >
                                      {hasInnerSubItems ? (
                                        <li className="drop-menu-item dropdown">
                                          <a
                                            className="dropdown-toggle"
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-expanded="false"
                                            href="/#"
                                            onClick={(e) => e.preventDefault()}
                                          >
                                            {subItem.label}
                                          </a>
                                          <MenuDropdown
                                            className="menu-dropdown dropdown-right"
                                            dark={isDark && !showReveal ? 1 : 0}
                                          >
                                            {subItem.items.map(
                                              (itemInner, indexInnerMost) => (
                                                <li
                                                  className="drop-menu-item"
                                                  key={
                                                    itemInner.name +
                                                    indexInnerMost
                                                  }
                                                >
                                                  {itemInner.isExternal ? (
                                                    <a
                                                      href={`${itemInner.name}`}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                    >
                                                      {itemInner.label}
                                                    </a>
                                                  ) : (
                                                    <LocalizedLink
                                                      partiallyActive={true}
                                                      activeClassName="active"
                                                      to={`/${itemInner.name}`}
                                                    >
                                                      {itemInner.label}
                                                    </LocalizedLink>
                                                  )}
                                                </li>
                                              ),
                                            )}
                                          </MenuDropdown>
                                        </li>
                                      ) : (
                                        <li className="drop-menu-item">
                                          {subItem.isExternal ? (
                                            <a
                                              href={`${subItem.name}`}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              {subItem.label}
                                            </a>
                                          ) : (
                                            <LocalizedLink
                                              to={`/${subItem.name}`}
                                              partiallyActive={true}
                                              activeClassName="active"
                                            >
                                              {subItem.label}
                                            </LocalizedLink>
                                          )}
                                        </li>
                                      )}
                                    </React.Fragment>
                                  )
                                })}
                              </MenuDropdown>
                            </li>
                          ) : (
                            <li className="nav-item" {...rest}>
                              {isExternal ? (
                                <a
                                  className="nav-link"
                                  href={`${name}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {label}
                                </a>
                              ) : (
                                <LocalizedLink
                                  className="nav-link"
                                  to={`/${name}`}
                                  partiallyActive={name === "" ? false : true}
                                  activeClassName="active"
                                  role="button"
                                  aria-expanded="false"
                                >
                                  {label}
                                </LocalizedLink>
                              )}
                            </li>
                          )}
                        </React.Fragment>
                      )
                    },
                  )}
                  {/* 
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link"
                      role="button"
                      data-toggle="dropdown"
                      aria-expanded="false"
                      href="/#"
                      onClick={(e) => e.preventDefault()}
                    >
                      <FaGlobe />
                    </a>
                    <MenuDropdown
                      className="menu-dropdown"
                      dark={isDark ? 1 : 0}
                    >
                      {config.map((lang) => (
                        <li
                          className="drop-menu-item"
                          key={lang.code}
                        >
                          <LocalizedLink to={pageContext.originalPath} language={lang.code} className={`dropdown-item${lang.code === locale ? " active" : ""}`} key={lang.code}>
                            {lang.name}
                          </LocalizedLink>
                        </li>
                      ))}
                    </MenuDropdown>
                  </li>
                  */}
                </Menu>
              </div>
            </div>
            <div className="header-btns ml-auto ml-xl-0 d-none d-md-block">
              <LocalizedLink to="/contact">
                <Button
                  size="sm"
                  css={`
                    font-size: 16px !important;
                    min-width: 141px !important;
                    height: 45px !important;
                  `}
                >
                  <Trans>Kontakt aufnehmen</Trans>
                </Button>
              </LocalizedLink>
            </div>

            <ToggleButton
              className={`navbar-toggler btn-close-off-canvas ml-3 pr-0 ${gContext.visibleOffCanvas ? "collapsed" : ""
                }`}
              type="button"
              data-toggle="collapse"
              data-target="#mobile-menu"
              aria-controls="mobile-menu"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={gContext.toggleOffCanvas}
              dark={isDark && !showReveal ? 1 : 0}
              css={{ "border": "0" }}
            >
              <MdMenu size="1.5em" />

            </ToggleButton>
          </nav>
        </Container>
      </SiteHeader>
      <Offcanvas
        show={gContext.visibleOffCanvas}
        onHideOffcanvas={gContext.toggleOffCanvas}
      >
        <NestedMenu menuItems={menuItems} />
        <LocalizedLink to="/contact">
          <Button
            className="mt-5 btn-block"
            size="sm"
            css={`
              font-size: 16px !important;
              min-width: 141px !important;
              height: 45px !important;
            `}
          >
            <Trans>Kontakt aufnehmen</Trans>
          </Button>
        </LocalizedLink>
      </Offcanvas>
    </>
  )
}
export default Header
