import React from "react";
import { GlobalProvider } from "./src/context/GlobalContext";
import Layout from "./src/components/Layout";
import { LocaleProvider } from "gatsby-theme-i18n/src/context";
import { SEO } from "gatsby-theme-i18n/src/components/seo";
import { I18nProvider } from "@lingui/react";
import { i18n } from "@lingui/core";
import { staticActivate } from "./src/utils/i18n";

export const wrapPageElement = ({ element, props }) => {
  const locale = props.pageContext.locale;
  staticActivate(locale);
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return (
    <LocaleProvider pageContext={props.pageContext}>
      <SEO location={props.location} pageContext={props.pageContext} />
      <I18nProvider i18n={i18n}>
        <Layout {...props}>{element}</Layout>
      </I18nProvider>
    </LocaleProvider>
  );
};

export const wrapRootElement = ({ element }) => (
  <GlobalProvider>{element}</GlobalProvider>
);
