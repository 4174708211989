import React from "react"
import styled from "styled-components"
import { color, space, typography, shadow } from "styled-system"
import { device } from "../../utils"

const HeroTitle = styled.h1`
  letter-spacing: -2.81px;
  font-size: 50px;
  line-height: 56px;
  margin-bottom: 30px;

  @media ${device.sm} {
    font-size: 66px;
    line-height: 70px;
  }

  @media ${device.lg} {
    font-size: 76px;
    line-height: 84px;
  }

  @media ${device.xl} {
    font-size: 80px;
    line-height: 84px;
  }
`

const SectionTitle = styled.h2`
  font-weight: 700;
  letter-spacing: -2.5px;
  font-size: 35px;
  line-height: 54px;
  margin-bottom: 16px;
  overflow-wrap: break-word;

  @media ${device.sm} {
    font-size: 40px;
    line-height: 62px;
  }

  @media ${device.lg} {
    font-size: 45px;
    line-height: 70px;
    margin-bottom: 30px;
  }

  ${color};
  ${space};
  ${typography};
  ${shadow};
`

const CardTitle = styled.h3`
  font-size: 21px;
  font-weight: 700;
  letter-spacing: -0.66px;
  line-height: 28px;
  ${color};
  ${space};
  ${typography};
  ${shadow};
`

const Title = ({ variant, ...rest }) => {
  switch (variant) {
    case "card":
      return <CardTitle color="heading" {...rest} />
    case "hero":
      return <HeroTitle color="heading" {...rest} as="h1" />
    default:
      return <SectionTitle color="heading" {...rest} as="h2" />
  }
}

export default Title
