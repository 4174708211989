import { i18n } from "@lingui/core"

export function getMenuItems() {
  return [
    {
      name: "",
      label: i18n._("Home"),
    },
    // {
    //   name: "services",
    //   label: i18n._("Leistungen"),
    //   items: [
    //     { name: "services/ai-consulting", label: i18n._("KI Beratung") },
    //     { name: "services/ai-development", label: i18n._("KI Entwicklung") },
    //     /*{ name: "ki-technologien", label: "Technologien" },*/
    //     { name: "services/ai-workshops", label: i18n._("KI Workshops") },
    //     { name: "services/data-labeling", label: i18n._("Daten Labeling") },
    //   ]
    // },
    {
      name: "services",
      label: i18n._("Services"),
    },
    {
      name: "industries",
      label: i18n._("Industrien"),
    },
    {
      name: "applications",
      label: i18n._("Anwendungen"),
    },
    // {
    //   name: "customer-stories",
    //   label: i18n._("Referenzen")
    // },
    {
      name: "demos",
      label: i18n._("Demos"),
    },

    {
      name: "career",
      label: i18n._("Karriere"),
    },
    {
      name: "blog",
      label: i18n._("Blog"),
    },
    /*{
    name: "pages",
    label: "Unternehmen",
    items: [
      { name: "about", label: "Über uns" },
      {
        name: "career",
        label: "Career",
        items: [
          { name: "career", label: "Career List" },
          { name: "career-details", label: "Career Details" },
        ],
      },
    ]
  },
  {
    name: "pages",
    label: "Pages",
    items: [
      { name: "about", label: "About" },
      {
        name: "career",
        label: "Career",
        items: [
          { name: "career", label: "Career List" },
          { name: "career-details", label: "Career Details" },
        ],
      },
      {
        name: "case-study",
        label: "Case Study",
        items: [
          { name: "case-study", label: "Case Study" },
          { name: "case-study-details", label: "Case Study Details" },
        ],
      },
      {
        name: "blog",
        label: "Blog",
        items: [
          { name: "blog-regular", label: "Blog Regular" },
          { name: "blog-left-sidebar", label: "Blog Left Sidebar" },
          { name: "blog-right-sidebar", label: "Blog Right Sidebar" },
          { name: "blog-details", label: "Blog Details" },
        ],
      },
      {
        name: "contact",
        label: "Contact",
        items: [
          { name: "contact-1", label: "Contact 01" },
          { name: "contact-2", label: "Contact 02" },
          { name: "contact-3", label: "Contact 03" },
        ],
      },
      {
        name: "account",
        label: "Account",
        items: [
          { name: "signin", label: "Signin" },
          { name: "signin-bg", label: "Signin BG" },
          { name: "signup", label: "Signup" },
          { name: "signup-bg", label: "Signup BG" },
          { name: "reset-pass", label: "Reset Password" },
          { name: "reset-pass-bg", label: "Reset Password BG" },
        ],
      },
      {
        name: "pricing",
        label: "Pricing",
        items: [
          { name: "pricing-1", label: "Pricing 01" },
          { name: "pricing-2", label: "Pricing 02" },
        ],
      },
      {
        name: "utility",
        label: "Utility",
        items: [
          { name: "coming-soon", label: "Coming Soon" },
          { name: "coming-soon-2", label: "Coming Soon 02" },
          { name: "404", label: "404 Page" },
          { name: "thank-you", label: "Thank You" },
          { name: "terms-conditions", label: "Terms & Conditions" },
        ],
      },
      {
        name: "essential",
        label: "Essential",
        items: [
          { name: "faq", label: "FAQ" },
          { name: "reviews", label: "Reviews" },
        ],
      },
    ],
  },*/
  ]
}
