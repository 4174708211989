import React from "react"
import styled from "styled-components"
import {
  Container,
  Row,
  Col,
  DropdownButton,
  ButtonGroup,
} from "react-bootstrap"
import { color, space, typography, shadow } from "styled-system"
import { Box } from "../Core"
import Logo from "../Logo"
import { LocalizedLink, useLocalization } from "gatsby-theme-i18n"
import { FaGithub } from "@react-icons/all-files/fa/FaGithub"
import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter"
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin"
import { FaXing } from "@react-icons/all-files/fa/FaXing"

import { Trans } from "@lingui/macro"

import de from "../../assets/image/svg/flags/de.svg"
import en from "../../assets/image/svg/flags/en.svg"

const TitleStyled = styled.div`
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
  line-height: 28px;
  margin-bottom: 22px;
  ${color};
  ${space};
  ${typography};
  ${shadow};
`

const UlStyled = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    line-height: 2.25;
    a {
      color: ${({ theme, color }) => theme.colors[color]} !important;
      &:hover {
        text-decoration: none;
        color: ${({ theme, color }) => theme.colors.secondary} !important;
      }
    }
  }
`

const DescriptionStyled = styled.p`
  color: ${({ dark, theme }) =>
    dark ? theme.colors.lightShade : theme.colors.darkShade};
  font-size: 15px;
`

const CopyRightArea = styled.div`
  border-top: ${({ dark, theme }) =>
    dark ? `1px solid #2f2f31 ` : `1px solid ${theme.colors.border}`};

  padding: 15px 0;
  p {
    color: ${({ dark, theme }) =>
      dark ? theme.colors.lightShade : theme.colors.darkShade};
    font-size: 13px;
    font-weight: 300;
    letter-spacing: -0.41px;
    line-height: 38px;
    margin-bottom: 0;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-flex;
    a {
      font-size: 16px;
      padding: 0 3px;
      margin: 0 2.5px;
    }
  }
  a {
    color: ${({ dark, theme }) =>
      dark ? theme.colors.lightShade : theme.colors.darkShade} !important;
    transition: 0.4s;
    &:visited {
      text-decoration: none;
    }
    &:hover {
      text-decoration: none;
      color: ${({ theme, color }) => theme.colors.secondary} !important;
    }
  }
`

const LangIcon = styled.img`
  border-radius: 20%;
  margin-bottom: 3px;
  margin-right: 3px;
  width: 19px;
  height: 14px;
`

const FooterSpan = styled.span`
  padding-right: 32px;
  white-space: nowrap;
  display: inline-block;
`

const renderLangIcon = (lang) => {
  return <LangIcon src={lang === "de" ? de : en} alt="" className="d-inline img-fluid" />
}

const renderCurrentLang = (locale, config) => {
  return (
    <>
      {renderLangIcon(locale)}{" "}
      {config.find((lang) => lang.code === locale).name}
    </>
  )
}

const Footer = ({ isDark = true, pageContext }) => {
  const { locale, config } = useLocalization()
  return (
    <>
      {/* <!-- Footer section --> */}
      <Box bg={isDark ? "dark" : "light"}>
        <Container>
          <Box
            css={`
              padding: 80px 0 60px;
            `}>
            <Row className="justify-content-center">
              <Col lg="4" md="4">
                <TitleStyled variant="card" color={isDark ? "light" : "dark"}>
                  <Logo white={isDark} />
                </TitleStyled>
                <DescriptionStyled dark={isDark}>
                  AMAI - Your Guide to AI.<br/>
                  Expertise from Strategy to Code.
                </DescriptionStyled>
                <DropdownButton
                  as={ButtonGroup}
                  drop="down"
                  variant="primary"
                  title={renderCurrentLang(locale, config)}>
                  {config.map((loc) => (
                    <div key={loc.code}>
                      {loc.code !== locale && (
                        <LocalizedLink
                          to={pageContext.originalPath}
                          language={loc.code}
                          className="dropdown-item">
                          {renderLangIcon(loc.code)} {loc.name}
                        </LocalizedLink>
                      )}
                    </div>
                  ))}
                </DropdownButton>
              </Col>
              <Col lg="7" md="7" className="mt-5 offset-md-1 mt-md-0">
                <Row>
                  <Col xs="6" xl="3">

                    <div className="mb-5 mb-xl-5">
                      <TitleStyled
                        variant="card"
                        color={isDark ? "light" : "dark"}
                      >
                        <Trans>Leistungen</Trans>
                      </TitleStyled>
                      <UlStyled color={isDark ? "lightShade" : "darkShade"}>
                        <li>
                          <LocalizedLink to="/services/consulting">
                            <Trans>KI Beratung</Trans>
                          </LocalizedLink>
                        </li>
                        <li>
                          <LocalizedLink to="/services/engineering">
                            <Trans>KI Entwicklung</Trans>
                          </LocalizedLink>
                        </li>
                        <li>
                          <LocalizedLink to="/services/contracting">
                            <Trans>KI Contracting</Trans>
                          </LocalizedLink>
                        </li>
                        {/* <li>
                          <LocalizedLink to="/services/ai-workshops">
                            <Trans>KI Workshops</Trans>
                          </LocalizedLink>
                        </li> */}
                      </UlStyled>
                    </div>

                  </Col>
                  <Col xs="6" xl="3">
                    <div className="mb-5 mb-xl-5">
                      <TitleStyled
                        variant="card"
                        color={isDark ? "light" : "dark"}>
                        <Trans>Ressourcen</Trans>
                      </TitleStyled>
                      <UlStyled color={isDark ? "lightShade" : "darkShade"}>
                        <li>
                          <LocalizedLink to="/industries">
                            <Trans>Industrien</Trans>
                          </LocalizedLink>
                        </li>
                        <li>
                          <LocalizedLink to="/applications">
                            <Trans>Anwendungen</Trans>
                          </LocalizedLink>
                        </li>
                        {/*
                        <li>
                          <LocalizedLink to="/customer-stories">
                            <Trans>Referenzen</Trans>
                          </LocalizedLink>
                        </li>
                         */}
                        <li>
                          <LocalizedLink to="/demos">
                            <Trans>Demos</Trans>
                          </LocalizedLink>
                        </li>
                      </UlStyled>
                    </div>
                  </Col>
                  <Col xs="6" xl="3">
                    <div className="mb-5 mb-xl-4">
                      <TitleStyled
                        variant="card"
                        color={isDark ? "light" : "dark"}>
                        <Trans>Unternehmen</Trans>
                      </TitleStyled>
                      <UlStyled color={isDark ? "lightShade" : "darkShade"}>
                        <li>
                          <LocalizedLink to="/about">
                            <Trans>Über Uns</Trans>
                          </LocalizedLink>
                        </li>
                        <li>
                          <LocalizedLink to="/blog">
                            <Trans>Blog</Trans>
                          </LocalizedLink>
                        </li>
                        <li>
                          <LocalizedLink to="/career">
                            <Trans>Karriere</Trans>
                          </LocalizedLink>
                        </li>
                      </UlStyled>
                    </div>
                  </Col>
                  <Col xs="6" xl="3">
                    <div className="mb-xl-4">
                      <TitleStyled
                        variant="card"
                        color={isDark ? "light" : "dark"}>
                        <Trans>Kontakt</Trans>
                      </TitleStyled>
                      <UlStyled color={isDark ? "lightShade" : "darkShade"}>
                        <li>
                          <a href="mailto:hi@am.ai">hi@am.ai</a>
                        </li>
                        <li>
                          <a href="tel:004972127664476">+49 721 27664476</a>
                        </li>
                        <li>
                          <LocalizedLink to="/appointment">
                            <Trans>Termin vereinbaren</Trans>
                          </LocalizedLink>
                        </li>
                      </UlStyled>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Box>
          <CopyRightArea dark={isDark ? 1 : 0}>
            <Row className="align-items-center">
              <Col sm="8" className="text-sm-left text-center mb-2 mb-sm-0">
                <p>
                  <FooterSpan>
                    &copy; {new Date().getFullYear()} AMAI GmbH
                  </FooterSpan>
                  <FooterSpan>
                    <LocalizedLink to="/imprint">
                      <Trans>Impressum</Trans>
                    </LocalizedLink>
                  </FooterSpan>
                  <FooterSpan>
                    <LocalizedLink to="/privacy">
                      <Trans>Datenschutz</Trans>
                    </LocalizedLink>
                  </FooterSpan>
                  <FooterSpan>
                    <LocalizedLink to="/blog/no-cookies">
                      <Trans>
                        Keine{" "}
                        <span role="img" aria-label="cookie">
                          🍪
                        </span>
                      </Trans>
                    </LocalizedLink>
                    {/*Und sind CO<sub>2</sub>-Neutral <span role="img" aria-label="tree">🌳</span>*/}
                  </FooterSpan>
                </p>
              </Col>
              <Col sm="4" className="text-sm-right text-center">
                <ul className="social-icons">
                  <li>
                    <a
                      href="https://linkedin.com/company/amai-gmbh"
                      target="_blank"
                      rel="noreferrer"
                      aria-label="Linkedin">
                      <FaLinkedin />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.xing.com/companies/amaigmbh"
                      target="_blank"
                      rel="noreferrer"
                      aria-label="Xing">
                      <FaXing />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/amaigmbh"
                      target="_blank"
                      rel="noreferrer"
                      aria-label="Twitter">
                      <FaTwitter />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://github.com/AMAI-GmbH"
                      target="_blank"
                      rel="noreferrer"
                      aria-label="Github">
                      <FaGithub />
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </CopyRightArea>
        </Container>
      </Box>
    </>
  )
}

export default Footer
