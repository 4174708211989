/* global GATSBY_THEME_I18N_LINGUI */
import { i18n } from "@lingui/core"
import { en, de } from "make-plural/plurals"
import catalogEN from "../../i18n/lingui/en/messages.js"
import catalogDE from "../../i18n/lingui/de/messages.js"

export const locales = {
  en: "English",
  de: "Deutsch",
}
export const defaultLocale = "de"

i18n.loadLocaleData({
  en: { plurals: en },
  de: { plurals: de },
})

i18n.load({
  en: catalogEN.messages,
  de: catalogDE.messages,
})

/**
 * We do a dynamic import of just the catalog that we need
 * @param locale any locale string
 */
export async function dynamicActivate(locale) {
  const { messages } = await import(
    `${GATSBY_THEME_I18N_LINGUI}/${locale}/messages.js`
  )
  i18n.load(locale, messages)
  i18n.activate(locale)
}

export function staticActivate(locale) {
  if (locale === "de") {
    i18n.activate("de")
  } else {
    i18n.activate("en")
  }
}
