import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "react-bootstrap"
import { LocalizedLink as Link } from "gatsby-theme-i18n"
import { t, Trans } from "@lingui/macro"

import { Title, Box, Text, Button } from "../../components/Core"
import { device } from "../../utils"

import { useStaticQuery, graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image"



const CaseCardStyled = styled(Box)`
  width: 100%;
  max-width: 450px;
  transition: 0.4s;
  border-radius: 8px;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top:3em;
  margin-bottom: 4em;
  margin-left: 0.5em;
  margin-right:0.5em;

  .img-container {
    position: relative;
    z-index: 1;
    object-fit: none;
    padding: 0px;

    img {
      border-radius: 0px;
      max-width: 100%;
      filter: grayscale(29%); -webkit-filter: grayscale(29%); -moz-filter: grayscale(29%);
    }

  }

  background: linear-gradient(225deg, #ffffff, #f9f9f9);

    box-shadow: -1px 3px 5px #d9d9d9, 0px -6px 6px -3px #d9d9d9dd, 0px 30px 50px -20px #d9d9d977;
  

  &:hover {
    transform: translateY(+2px);
    box-shadow: -2px 2px 6px #d9d9d9, 2px -2px 3px #ffffff;
  }
  &:hover i {
    transform: translateX(10px);
    opacity: 1;
  }
`

const BtnContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  margin-top: 20px;
  opacity: 0;
  transition: 0.4s opacity, 0.4s margin-top;
  height: 40px;
  font-size: 16px;
  text-align: center;
  ${CaseCardStyled}:hover & {
    opacity: 1;
    margin-top: 0px;
  }
  button {
    font-size: 16px;
    padding: 0.5rem 1.25rem;
    @media ${device.sm} {
      font-size: 18px;
      padding: 0.75rem 1.5rem;
    }
    @media ${device.lg} {
      padding: 0.85rem 1.75rem;
    }
  }
`

const TextContent = styled(Box)`
  position: relative;
  z-index: 1;
  transition: 0.4s;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-top: 18px;
  padding-bottom: 21px;
  padding-left: 20px;
  padding-right: 20px;

  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @media ${device.sm} {
    padding-top: 33px;
    padding-bottom: 41px;
    padding-left: 38px;
    padding-right: 38px;
  }
`

const PreTitle = styled(Text)`
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 28px;
  margin-bottom: 0px!important;
`

const TitleStyled = styled(Title)`
  letter-spacing: -1.06px;
  margin-bottom: 22px;
  margin-top: 22px!important;
  font-size: 28px;
  font-weight: 700;
  line-height: 42px;
  @media ${device.sm} {
    font-size: 34px;
    font-weight: 700;
  }
`


const CaseCard = ({
  isDark = false,
  bg,
  img,
  meta = "",
  title = "",
  children = "",
  link = "/case-studies",
  readMore = <Trans>Mehr Lesen</Trans>,
  buttonText = <Trans>Case Study lesen</Trans>,
  ...rest
}) => {
  const { images } = useStaticQuery(
    graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    `,
  )

  const image = images.edges.find(n => {
    return n.node.relativePath.includes(img);
  });
  if (!image) { console.log("No image with given filename available in src/assets/images"); return null; }


  const imgGatsby = [
    getSrc(image.node.childImageSharp.gatsbyImageData),
  ]


  return (
    <CaseCardStyled bg={bg} md="6"
      sm="11"
      className="my-4"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-once="true">
      <Link to={link}>
        <div className="img-container">
          <img src={imgGatsby} alt="" style="margin:0"></img>
          <BtnContainer>
            <Button color="dark" bg="white"><Trans>{buttonText}</Trans></Button>
          </BtnContainer>
        </div>
      </Link>
      <TextContent bg={bg}>
        <PreTitle color={isDark ? "lightShade" : "darkShade"}>{meta}</PreTitle>
        <TitleStyled color={isDark ? "light" : "dark"}><Link to={link}>{title}</Link></TitleStyled>
        <Text className="tw-grow" color={isDark ? "lightShade" : "darkShade"}>{children}</Text>
        <Text className="tw-mt-4" color={isDark ? "lightShade" : "darkShade"}><Link to={link}><Trans>{readMore}</Trans>&nbsp; →</Link></Text>
      </TextContent>
    </CaseCardStyled >
  )
}

export default CaseCard
