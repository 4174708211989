import React from "react"
import { t } from "@lingui/macro"

export default function NewsletterSignup() {
  return (
    <div id="newsletter_signup">
      <div>
        <link
          href="//cdn-images.mailchimp.com/embedcode/slim-10_7.css"
          rel="stylesheet"
          type="text/css"
          rel="noreferrer"
        />
        <style type="text/css">
          {`  #mc_embed_signup {
            clear: left;
            font: Helvetica, Arial, sans-serif;
            }
            #mc_embed_signup form {
                padding:0;
            }
            .field-subscribe {
            width:320px;
            min-width: 100px;
            max-width: 100%;
            transition: 0.05s;
            border-width: 0px;
            border-radius: 2px;
            textAlign: center;
            padding: 16px;
            margin: 12px;
            }
            .btn-subscribe {
            display: block;
            width: 320px;
            max-width: 100%;
            transition: 0.05s;
            background: whitesmoke;
            border: 0;
            border-width: 0;
            border-radius: 2px;
            box-shadow: 0px 0px 0px 1px #206287;
            padding: 16px;
            margin: 12px;
            }
            .btn-subscribe:hover {
            box-shadow: 0px 0px 0px 4px #206287,
            0 1.8px 2.2px rgba(0, 0, 0, 0.034),
            0 4.7px 5.3px rgba(0, 0, 0, 0.048),
            0 7.5px 10px rgba(0, 0, 0, 0.06),
            0 12.3px 17.9px rgba(0, 0, 0, 0.072),
            0 21.8px 33.4px rgba(0, 0, 0, 0.086),
            0 40px 80px rgba(0, 0, 0, 0.12);
            transition: 0.05s;
            }
            .bg-gray  {
               background-color: #ebebee;
               padding-top: 24px!important;
            }
        `}
        </style>
      </div>
      <div className="container tw-bg-amaigrey tw-bg-gray tw-text-center tw-mt-12 tw-p-12">
        <div>
          <span className="!tw-text-amaiblue tw-text-xl">
            {t`Zwei Minuten, um in AI auf dem Laufenden zu bleiben.`}</span>
        </div>
        <div className="tw-text-amaiblue tw-text-sm tw-max-w-[340px] tw-inline-block">
          <br />
          <a href="https://us2.campaign-archive.com/home/?u=b0d2b36b836cc3d28bd4a08e9&id=a0efd81845">
            {t`Erhalten Sie diesen Newsletter jede zweite Woche direkt in Ihren Posteingang. Abonnieren Sie hier und stöbern Sie durch alle bisherigen Ausgaben in unserem`}{" "}
            <u>{t`i.am.ai Newsletter Archiv`}</u>.
          </a>
        </div>
        <br />
        <br />
        <div id="mc_embed_signup">
          <form
            action="https://i.us2.list-manage.com/subscribe/post?u=b0d2b36b836cc3d28bd4a08e9&amp;id=a0efd81845"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            className="validate"
            target="_blank"
            noValidate>
            <div id="mc_embed_signup_scroll">
              <div className="mc-field-group">
                <input
                  type="text"
                  name="NAME"
                  className="px-2 py-3 mx-auto my-4 text-center row field-subscribe"
                  id="mce-NAME"
                  placeholder={t`Name`}
                />
              </div>
              <div className="mc-field-group">
                <input
                  type="email"
                  name="EMAIL"
                  className="px-2 py-3 mx-auto my-4 text-center row field-subscribe"
                  id="mce-EMAIL"
                  placeholder={t`Email (notwendig)`}
                />
              </div>
              <div id="mce-responses" className="clear">
                <div
                  className="response"
                  id="mce-error-response"
                  style={{ display: "none" }}></div>
                <div
                  className="response"
                  id="mce-success-response"
                  style={{ display: "none" }}></div>
              </div>
              <div
                style={{ position: "absolute", left: "-5000px" }}
                aria-hidden="true">
                <input
                  type="text"
                  name="b_b0d2b36b836cc3d28bd4a08e9_a0efd81845"
                  tabIndex="-1"
                  value=""
                />
              </div>
              <div className="clear">
                <input
                  type="submit"
                  value={t`Abonnieren`}
                  name="subscribe"
                  id="mc-embedded-subscribe"
                  className="px-2 py-3 mx-auto my-4 btn-subscribe"
                />
              </div>
            </div>
          </form>
        </div>
        <div className="tw-text-center tw-max-w-[340px] tw-inline-block tw-text-amaiblue tw-text-sm">
          <br />
          <span style={{ color: "grey" }}>
            {t`Wir verwenden Mailchimp als unsere Marketing-Plattform. Indem Sie auf "Abonnieren" klicken, bestätigen Sie, dass Ihre Daten zur Verarbeitung an Mailchimp übertragen werden. Erfahren Sie hier mehr über`}{" "}
            <a
              href="https://mailchimp.com/legal/"
              target="_blank">{t`Mailchimp's Datenschutzbestimmungen.`}</a>
          </span>
        </div>
      </div>
    </div>
  )
}
