import React, { useEffect, useLayoutEffect, useContext, useRef } from "react"

import { ThemeProvider } from "styled-components"
import { Helmet } from "react-helmet"
import AOS from "aos"

import Header from "../Header"
import Footer from "../Footer"

//import ModalVideo from "../ModalVideo";

import GlobalContext from "../../context/GlobalContext"

import GlobalStyle from "../../utils/globalStyle"

import SEO from "../SEO"

import "../../styles/global.css"

/*
import "../../assets/fonts/typography-font/Circular-Std-Book.ttf";
import "../../assets/fonts/typography-font/CircularStd-Black.ttf";
import "../../assets/fonts/typography-font/CircularStd-BlackItalic.ttf";
import "../../assets/fonts/typography-font/CircularStd-Bold.ttf";
import "../../assets/fonts/typography-font/CircularStd-BoldItalic.ttf";
import "../../assets/fonts/typography-font/CircularStd-Book.ttf";
import "../../assets/fonts/typography-font/CircularStd-BookItalic.ttf";
import "../../assets/fonts/typography-font/CircularStd-Medium.ttf";
import "../../assets/fonts/typography-font/CircularStd-MediumItalic.ttf";
*/

import "./bootstrap-custom.scss"
//import "../../../node_modules/slick-carousel/slick/slick.css";
//import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import "../../../node_modules/aos/dist/aos.css"

import "../../assets/fonts/typography-font/typo.css"

// the full theme object
import { theme as baseTheme } from "../../utils"

/*
const Loader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #fff;
  z-index: 9999999999;
  opacity: 1;
  visibility: visible;
  transition: all 1s ease-out 0.5s;
  &.inActive {
    opacity: 0;
    visibility: hidden;
  }
`;
*/

// options for different color modes
const modes = { light: "light", dark: "dark" }

// merge the color mode with the base theme
// to create a new theme object
const getTheme = (mode) => {
  var theme = { ...baseTheme }
  if (mode === modes.dark) {
    theme.colors = baseTheme.colors.modes.dark
  }
  return theme
}

const Helm = ({ location }) => {
  return (
    <>
      <Helmet>
        <title>AMAI GmbH</title>
        <script defer data-domain="am.ai" src="/js/script.js"></script>

        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.plausible = window.plausible || function() { (window.plausible.q = window.plausible.q || []).push(arguments) }",
          }}
        />
      </Helmet>
      <SEO pathname={location.pathname} />
    </>
  )
}

const Layout = ({ children, type, pageContext, location }) => {
  const gContext = useContext(GlobalContext)
  //const [visibleLoader, setVisibleLoader] = useState(true);

  useLayoutEffect(() => {
    AOS.init()
    //setVisibleLoader(false);
  }, [])

  // Navbar style based on scroll
  const eleRef = useRef()

  useEffect(() => {
    window.addEventListener(
      "popstate",
      function (event) {
        // The popstate event is fired each time when the current history entry changes.
        if (gContext.closeAbout) {
          gContext.closeAbout()
          gContext.closeContact()
          gContext.closeOffcanvas()
        }
      },
      false,
    )
    window.addEventListener(
      "pushState",
      function (event) {
        // The pushstate event is fired each time when the current history entry changes.
        if (gContext.closeAbout) {
          gContext.closeAbout()
          gContext.closeContact()
          gContext.closeOffcanvas()
        }
      },
      false,
    )
  }, [gContext])

  if (type === "bare") {
    return (
      <ThemeProvider
        theme={
          gContext.themeDark ? getTheme(modes.dark) : getTheme(modes.light)
        }>
        <GlobalStyle />
        <Helm location={location} />
        {/*<Loader id="loading" className={visibleLoader ? "" : "inActive"}>
          <div className="load-circle">
            <span className="one"></span>
          </div>
      </Loader>*/}
        <div className="site-wrapper overflow-hidden" ref={eleRef}>
          {children}
        </div>

        {/*<ModalVideo />*/}
      </ThemeProvider>
    )
  }

  return (
    <>
      <ThemeProvider
        theme={
          gContext.themeDark ? getTheme(modes.dark) : getTheme(modes.light)
        }>
        <GlobalStyle />
        <Helm location={location} />
        {/*<Loader id="loading" className={visibleLoader ? "" : "inActive"}>
          <div className="load-circle">
            <span className="one"></span>
          </div>
      </Loader>*/}
        <div className="site-wrapper overflow-hidden" ref={eleRef}>
          <Header isDark={gContext.headerDark} pageContext={pageContext} />
          {children}
          {!gContext.footerHide && (
            <Footer isDark={gContext.footerDark} pageContext={pageContext} />
          )}
        </div>

        {/*<ModalVideo />*/}
      </ThemeProvider>
    </>
  )
}

export default Layout
